/** @jsxImportSource @emotion/react */
import { BoxBody, BoxFooter, BoxHeader, WhiteBox } from '@/components/WhiteBox';
import { Button } from '@/components/Button';
import { Grid, GridContainer } from '@/components';
import { useTranslate } from '@/i18n/useTranslate';
import React, { FC, ReactNode } from 'react';
import { useIndividualNPI } from '@/store/npi-individual/hooks';
import { css } from '@mui/material';
import { Dl } from '@/components/Dl';
import { SubHeader } from '../SubHeader';

import { EMPTY } from '@/constants';
import { useSectionsOfNpiData } from './useSectionsOfNpiData';
import { NPI1ShortData } from '@/api/__generated__/webApi';

export const NPIDataConfirmationIndividual: FC = () => {
  const { npi, confirmed, id, resetNPIInfo, confirm, fetching } =
    useIndividualNPI(true);
  const { t } = useTranslate('profile');

  if (!npi || confirmed) {
    return null;
  }

  return (
    <NPIDataViewIndividual npi={npi} npiId={id}>
      <BoxFooter
        css={css`
          div:first-of-type {
            justify-content: end;
          }
        `}
        text={t('IS_THIS_REALLY_YOUR_DATA')}
      >
        <Button
          color={'primary'}
          variant={'contained'}
          onClick={confirm}
          disabled={fetching}
        >
          {t('YES')}
        </Button>
        <Button
          color={'primary'}
          variant={'outlined'}
          onClick={resetNPIInfo}
          disabled={fetching}
        >
          {t('NO')}
        </Button>
      </BoxFooter>
    </NPIDataViewIndividual>
  );
};

export const NPIDataIndividualInfo: FC = () => {
  const { npi, id, confirmed } = useIndividualNPI(true);
  if (!npi || !confirmed) {
    return null;
  }
  return <NPIDataViewIndividual npi={npi} npiId={id} />;
};

export const NPIDataViewIndividual: FC<{
  npi: NPI1ShortData;
  npiId: number | string | null | undefined;
  children?: ReactNode;
}> = ({ npi, npiId, children }) => {
  const sections = useSectionsOfNpiData(npi);
  return (
    <WhiteBox>
      <BoxHeader mb={{ xs: 36, sm: 64, lg: 36 }}>{`NPI #${
        npiId ?? '...'
      }`}</BoxHeader>
      <BoxBody>
        <GridContainer>
          <Grid xs={12} sm={6} md={4}>
            <SubHeader>{sections?.common.title}</SubHeader>
            {sections?.common.items?.map((item) => {
              return (
                <Dl key={item.label}>
                  <dt>{item.label}</dt>
                  <dd>{item.value || EMPTY}</dd>
                </Dl>
              );
            })}
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <SubHeader>{sections?.address.title}</SubHeader>
            {sections?.address.items?.map((item) => {
              return (
                <Dl key={item.label}>
                  <dt>{item.label}</dt>
                  <dd>{item.value || EMPTY}</dd>
                </Dl>
              );
            })}
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <SubHeader>{sections?.additional.title}</SubHeader>
            {sections?.additional.items?.map((item) => {
              return (
                <Dl key={item.label}>
                  <dt>{item.label}</dt>
                  <dd>{item.value || EMPTY}</dd>
                </Dl>
              );
            })}
          </Grid>
        </GridContainer>
      </BoxBody>
      {children}
    </WhiteBox>
  );
};
